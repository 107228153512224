export default  {
    createStore: "CREATE_STORE",
    getStores: "GET_STORES",
    deleteStores: "DELETE_STORE",
    createStoreProcess: "CREATE_STORE_PROCESS",
    stopStoreProcess: "STOP_STORE_PROCESS",
    restartStoreProcess: "RESTART_STORE_PROCESS",
    deleteStoreProcess: "DELETE_STORE_PROCESS",
    getStoreProcesses: "GET_STORE_PROCESSES",
    getStoreNotifications: "GET_STORE_NOTIFICATIONS",
    getStoreErrors: "GET_STORE_ERRORS",
    batchUpdateStoreNotifications: "BATCH_UPDATE_STORE_NOTIFICATIONS",
    batchRestartProcesses: "BATCH_RESTART_PROCESSES",
    batchStopProcesses: "BATCH_STOP_PROCESSES",
    batchDeleteProcesses: "BATCH_DELETE_PROCESSES",
    clearError: "CLEAR_ERROR",
    clearNotification: "CLEAR_NOTIFICATION",
    updateProcess: "UPDATE_PROCESS",
    login: "LOGIN",
    getUser: "GETUSER",
    reInitiate: "REINITIATE"
}