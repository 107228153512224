import "./App.css";
import { ReactDOM, useEffect, useState } from "react";
import {
    BrowserRouter,
    Routes,
    Route,
    useSearchParams,
} from "react-router-dom";
import Header from "./components/Header";
import Product from "./components/Product";
import BotTable from "./components/BotTable";
import ErrorLog from "./components/ErrorLog";
import BotInfo from "./Pages/BotInfo";
import ProductDetailsPage from "./Pages/ProductDetailsPage";
import ContextStore from "./Context/ContextStore";
import { io } from "socket.io-client";
import Spinkit from "./components/Spinkit/Spinkit";
import LoadingOverlay from "react-loading-overlay";
import { Modal } from "react-bootstrap";
import LoginModal from "./components/LoginModal";
import { Login } from "./components/Login";
import dispatch from "./dispatch/dispatch";
import actions from "./dispatch/actions";

function App() {
    const [showSpinner, setShowSpinner] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);
    const [password, setPassword] = useState("");
    useEffect(() => {
        (async () => {
            const token = localStorage.getItem("token");
            if (token) {
                setShowSpinner(true)
                const userResponse = await dispatch(
                    actions.getUser,
                    {},
                    {}, token
                );
                console.log(userResponse)
                if(userResponse.error){
                    setShowSpinner(false);
                    return console.log(userResponse.error.message);
                }
                setContextStore((past) => {
                    return {
                        ...past,
                        user: userResponse,
                        token,
                    };
                });
                setShowSpinner(false);
            }
        })();
    }, []);
    const [contextStore, setContextStore] = useState({
        stores: [],
        store: {},
        storeNotifications: [],
        storeErrors: [],
        storeProcesses: [],
        storeProcess: [],
        processNotifications: [],
        processErrors: [],
        setShowSpinner,
        user: {},
    });
    return (
        <div className="App">
            <ContextStore.Provider value={{ contextStore, setContextStore }}>
                {/* {!loggedIn && <LoginModal show={!loggedIn} setShow={(val) => setLoggedIn(!val)}/>} */}
                <LoadingOverlay active={showSpinner} spinner text={"Loading"}>
                    <BrowserRouter>
                        {contextStore.user._id ? (
                            <Routes>
                                <Route exact path="/" element={<BotInfo />} />
                            </Routes>
                        ) : (
                            <Routes>
                                <Route exact path="/" element={<Login />} />
                            </Routes>
                        )}
                    </BrowserRouter>
                </LoadingOverlay>
            </ContextStore.Provider>
        </div>
    );
}

export default App;
