import {  useState, useContext } from "react";

import { useNavigate } from "react-router-dom";




import "./Login.css";
import ContextStore from "../Context/ContextStore";
import dispatch from "../dispatch/dispatch";
import actions from "../dispatch/actions";

export const Login = () => {
    const navigate = useNavigate();
    const { contextStore, setContextStore } = useContext(ContextStore);
    const [formData, setFormData] = useState({
        email: "",
        password: "",
    });
    const [errors, setErrors] = useState();
    const loginFormValidation = ({
        email,
        password,
      }) => {
        let errors = {};
        if (email.length === 0) {
          errors = { ...errors, email: 'Required' };
        } else if (
          !String(email)
            .toLowerCase()
            .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
        ) {
          errors = { ...errors, email: 'Valid Email Required' };
        }
        if (password.length === 0) {
          errors = { ...errors, password: 'Required' };
        }
      
        return errors;
      };
    const handleSubmit = async (event) => {
        event.preventDefault();
        const vErrors = loginFormValidation(formData);
        setErrors(vErrors);
        if (!vErrors.email && !vErrors.password) {
            contextStore.setShowSpinner(true)
            const tokenResponse= await dispatch (
                actions.login,
                {},
                { email: formData.email, password: formData.password }
            );
            if (typeof tokenResponse === "string") {
                const userResponse =
                    await dispatch(actions.getUser, {}, {}, tokenResponse);
                if ("error" in userResponse) {
                    contextStore.setShowSpinner(false)
                    return console.log(userResponse.error.message);
                }
                localStorage.setItem("token", tokenResponse);
                setContextStore((past) => {
                    return {
                        ...past,
                        user: userResponse,
                        token: tokenResponse,
                    }
                })
                contextStore.setShowSpinner(false)
                navigate("/");
            } else {
                contextStore.setShowSpinner(false)
                alert(tokenResponse.error.message)
                console.log(tokenResponse.error.message);
            }
        }
    };
    const handleChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };
    return (
        <div className="login">
            <div className="login__container">
                <div className="login__logo">
                    <span className="accent">Yapa</span>
                    <span className="secondary">Shop</span>
                </div>
                <form className="login__form" onSubmit={handleSubmit}>
                    <div>
                        <label className="login__label">Login ID</label>
                        <input
                            value={formData.email}
                            type="text"
                            name="email"
                            className={
                                errors?.email
                                    ? "login__input error__input"
                                    : "login__input"
                            }
                            placeholder="admin@gmail.com"
                            onChange={handleChange}
                        />
                    </div>
                    {errors?.email ? (
                        <div className="danger__text">{errors.email}</div>
                    ) : (
                        <br />
                    )}
                    <br />
                    <div>
                        <label className="login__label">Password</label>
                        <input
                            value={formData.password}
                            type="password"
                            name="password"
                            className={
                                errors?.password
                                    ? "login__input error__input"
                                    : "login__input"
                            }
                            placeholder="Type your password"
                            onChange={handleChange}
                        />
                    </div>
                    {errors?.password ? (
                        <div className="danger__text">{errors.password}</div>
                    ) : (
                        <br />
                    )}
                    <br />
                    <button type="submit" className="login__button">
                        Login
                    </button>
                </form>
            </div>
        </div>
    );
};
